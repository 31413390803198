import React from 'react'

const Details = () => (
  <>
    <div className='text-light'>
    <h3 className='pt-5'>Address</h3>
      <p>Pranapnea <br></br>
        Portugal - Madeira, Calheta<br></br>
      </p>
      <p><h3>Phone - Freediving</h3> (351) 927 564 669</p>
      <p><h3>Phone - Yoga - Massage</h3> (351) 960 311 725</p>
      <h3>Email</h3><a href='mailto:info@pranapnea.com'>info@pranapnea.com</a>
    </div>
  </>
)
export default Details
